<template>
    <div class="compares_header">
        <div class="auto-1440">
            <div class="left">
                <div class="title">
                    分屏
                    <br />
                    查看
                </div>
                <div class="list">
                    <div class="item" v-for="item in screen" :key="item.key">
                        <div class="label">{{ item.typeInfo.name }}版</div>
                        <h2 class="dot4">{{ item.info.Name }}</h2>
                        <div class="delete">
                            <a-tooltip>
                                <template slot="title">删除</template>
                                <div class="close" @click="close(item)">
                                    <svg-icon
                                        icon-class="icon_delete"
                                    ></svg-icon>
                                </div>
                            </a-tooltip>
                            <a-tooltip>
                                <template slot="title">下载</template>
                                <div
                                    class="close"
                                    @click="download(item.typeInfo.name)"
                                >
                                    <a-icon type="download" />
                                </div>
                            </a-tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right" v-if="condition">
                <div class="add" @click="addItem">
                    <svg-icon icon-class="icon_plus"></svg-icon>
                    <span>添加</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    // props:['condition','screen'],
    props: {
        condition: Boolean,
        screen: {
            type: Array,
            default: [],
        },
    },
    methods: {
        addItem() {
            this.$emit('add')
        },
        close(item) {
            this.$emit('close', item.key)
        },
        download(item) {
            this.$emit('download', item)
        },
    },
}
</script>
<style scoped lang="less">
.compares_header {
    background-color: #f5f5f5;
    padding: 10px 0;
    .auto-1440 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
            display: flex;
            align-items: center;
            flex: 1;
            .list {
                display: flex;
            }
        }
    }
    .title {
        font-size: 24px;
        color: rgb(0, 15, 35);
        font-weight: bold;
        line-height: 1.167;
        margin-right: 30px;
        flex-shrink: 0;
    }
    .list {
        flex: 1;
        .item {
            display: flex;
            padding: 10px;
            border-radius: 10px;
            background-color: #fff;
            width: calc(25% - 10px);
            margin-right: 10px;
            .label {
                font-size: 14px;
                width: 30px;
                text-align: center;
                padding: 15px 5px;
                line-height: 1;
                margin-right: 20px;
                flex-shrink: 0;
                font-weight: bold;
                color: #000;
                background-color: #00ffc0;
                border-radius: 10px 0 10px 0;
            }
            h2 {
                font-size: 14px;
                color: rgb(85, 85, 85);
                line-height: 1.714;
            }
            .delete {
                align-self: center;
                flex-shrink: 0;
                margin-left: 10px;
                transition: 0.3s;
                font-size: 16px;
                cursor: pointer;
                .close {
                    margin: 5px 0;
                }
                .svg-icon {
                    outline: none;
                }
                &:hover {
                    color: #f6a75c;
                }
            }
        }
    }
    .right {
        display: flex;
        align-items: center;
        .add {
            height: 120px;
            display: flex;
            width: 100px;
            flex-direction: column;
            background-color: #fff;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            cursor: pointer;
            transition: 0.3s;
            color: rgb(187, 187, 187);
            .svg-icon {
                font-size: 12px;
                margin-bottom: 10px;
            }
            span {
                font-size: 14px;
                line-height: 1;
            }
            &:hover {
                color: #00ffc0;
            }
        }
    }
}
</style>
