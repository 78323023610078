<template>
    <a-spin :spinning="spinning">
        <div class="compared">
            <a-button type="primary" class="goback" @click="$router.go(-1)">
                <a-icon type="left" />
                返回
            </a-button>
            <div class="auto-1440">
                <Breadcrumb type="grey" />
            </div>
            <CompareHeader
                :condition="condition"
                :screen="screen"
                @add="toogleAddDialog(true)"
                @close="closeScreen"
                @download="openDownload"
            />
            <div class="compare_list">
                <Outline
                    :length="screenLength"
                    v-for="item in screen"
                    :key="item.key"
                    :data="item"
                    @close="closeScreen"
                    @change="updateList"
                    :modelType="item.typeInfo"
                />
            </div>
            <AddDialog
                :modelType="modelType"
                :dialogVisable="isAddDialogShow"
                @close="toogleAddDialog(false)"
                @add="addItem"
            />
            <!-- 下载弹窗 -->
            <DownLoad
                :loading="confirmLoading"
                :dialogVisable="downloadDialog"
                @close="downloadDialog = false"
                @download="download"
                :model="curModel"
                ref="download"
            />
            <!-- 下载思维导图 -->
            <div
                style="
                    position: absolute;
                    z-index: -999;
                    left: -100%;
                    top: 0;
                    left: -100%;
                    width: 100%;
                    max-height: 100%;
                "
            >
                <template v-for="item in screen">
                    <JsMind
                        :key="item.id"
                        :modelType="item.typeInfo"
                        :data="item.treeData"
                        :rootName="item.info.Name"
                        v-if="item.treeData.length !== 0"
                        :ref="item.typeInfo.class"
                    />
                </template>
            </div>
        </div>
    </a-spin>
</template>
<script>
import Breadcrumb from '~/components/common/Breadcrumb'
import CompareHeader from '~/components/Learning/Compared/Header'
import Outline from '~/components/Learning/Compared/Outline'
import AddDialog from '~/components/Learning/Compared/AddDialog'
import DownLoad from '@/components/Learning/Info/DownLoad'
import { mapState, mapActions, mapMutations } from 'vuex'
import { ExportWord } from '@/api/export'
import { GetData } from '@/api/model'
import {
    GetAllListByModelId,
    GetExamListByModelId,
    GetKnowledgeListByModelId,
    GetNoteListByModelId,
} from '@/api/card'
import JsMind from '@/components/jsmind/Index.vue'
export default {
    components: {
        Breadcrumb,
        CompareHeader,
        Outline,
        AddDialog,
        DownLoad,
        JsMind,
    },
    data() {
        return {
            // 分屏列表
            screen: [],
            // 基础数据
            default: {
                id: '',
                type: '',
                typeInfo: {},
                key: '',
                title: '',
                info: {},
                treeData: [],
            },
            // 分屏数
            screenLength: 0,
            // 添加弹窗
            isAddDialogShow: false,
            // loading
            spinning: false,
            // 下载弹窗显示
            downloadDialog: false,
            // 选中下载模型内容
            downloadType: null,
            // 下载模型内容所有类型
            downLoadModel: [],
            // 缺人下载loading
            confirmLoading: false,
            // 选中模型类型
            curModel: {
                name: '',
            },
            getTree: {
                GetExamListByModelId: GetExamListByModelId,
                GetKnowledgeListByModelId: GetKnowledgeListByModelId,
                GetNoteListByModelId: GetNoteListByModelId,
            },
        }
    },
    computed: {
        condition() {
            return this.screenLength < 4
        },
        ...mapState({
            modelType: state => state.model.modelType,
        }),
    },
    watch: {
        modelType: {
            handler(newVal, oldVal) {
                this.downLoadModel = JSON.parse(JSON.stringify(newVal))
                this.downLoadModel.forEach(item => (item.tree = []))
            },
            immediate: true,
        },
        // 选中模型内容类型
        downloadType(val) {
            this.curModel = this.downLoadModel.find(
                item => item.name == this.downloadType
            )
        },
    },
    methods: {
        // 分屏
        async addScreen(id, type) {
            return await new Promise((resolve, reject) => {
                this.condition && this.screenLength++
                if (this.screenLength < 5) {
                    const typeInfo = this.modelType.find(
                        item => item.value == type
                    )
                    GetData({ id: id })
                        .then(res => {
                            const { data } = res
                            const newScreen = JSON.parse(
                                JSON.stringify(this.default)
                            )
                            newScreen.id = id
                            newScreen.type = type
                            newScreen.typeInfo = typeInfo
                            newScreen.info = data
                            newScreen.key = this.getUid()
                            this.screen.push(newScreen)
                            resolve()
                        })
                        .catch(err => {
                            reject(err)
                        })
                } else {
                    reject()
                }
            })
        },
        // 关闭当前分屏
        closeScreen(key) {
            const idx = this.screen.findIndex(item => item.key == key)
            if (this.screenLength > 1) {
                this.screen.splice(idx, 1)
                this.screenLength--
            } else {
                this.$router.go(-1)
            }
        },
        // 生成唯一ID
        getUid() {
            const id = Number(
                Math.random().toString().substr(3, 3) + Date.now()
            ).toString(36)
            const flag = this.screen.some(item => item.id === id)
            if (flag) {
                this.getUid()
            } else {
                return id
            }
        },
        // 打开添加对比模型弹窗
        toogleAddDialog(bool) {
            this.isAddDialogShow = bool
        },
        // 弹窗添加
        addItem(obj) {
            const { id, type } = obj
            this.addScreen(id, type)
        },
        // 首次添加
        async addFirst() {
            this.spinning = true
            const { id } = this.$route.params
            await Promise.all([
                this.addScreen(id, this.modelType[0].value),
                this.addScreen(id, this.modelType[1].value),
                this.addScreen(id, this.modelType[2].value),
            ])
            this.spinning = false
        },
        // 更新数据
        updateList(par) {
            const { id, treeData } = par
            let item = this.screen.find(item => item.info.Id == id)
            item.treeData = treeData
        },
        // 下载弹窗
        openDownload(type) {
            console.log(type)
            this.downloadType = type
            this.downloadDialog = true
        },
        async download(params) {
            console.log(params)
            this.confirmLoading = true
            const { form, isDownloadSwdt } = params
            if (isDownloadSwdt) {
                const pro = await Promise.all([
                    this.downloadContent(form),
                    this.downloadSwdt(),
                ])
                console.log(pro)
            } else {
                await this.downloadContent(form)
            }
            this.confirmLoading = false
            this.downloadDialog = false
        },
        // 下载内容
        downloadContent(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    const { data } = await ExportWord(params)
                    data.forEach((item, i) => {
                        let link = document.createElement('a')
                        link.href = item.url
                        link.download = item.name
                        link.target = '_blank'
                        setTimeout(() => {
                            link.click()
                            if (i == data.length - 1) {
                                resolve()
                            }
                        }, i * 100)
                    })
                } catch (error) {
                    reject(error)
                }
            })
        },
        // 思维导图
        downloadSwdt() {
            return new Promise(async (resolve, reject) => {
                try {
                    setTimeout(() => {
                        this.$refs[this.curModel.class][0].screen_shot()
                        resolve()
                    }, 500)
                } catch (error) {
                    reject(error)
                }
            })
        },
    },
    mounted() {
        this.addFirst()
    },
    created() {},
}
</script>
<style lang="less" scoped>
/deep/.breadcrumb {
    border-bottom: 0 !important;
    flex-shrink: 0;
}
/deep/.compares_header {
    flex-shrink: 0;
}
.compared {
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
}
.compare_list {
    padding: 50px 40px 50px;
    display: flex;
    flex: 1;
    .outline {
        border-right: 1px solid #e5e5e5;
        // flex: 1;
        // width: 100%;
        &:last-of-type {
            border-right: 0;
        }
    }
}
/deep/.ant-spin-blur::after {
    background-color: #000;
}
.goback {
    position: absolute;
    left: 0.5rem;
    top: 0.2rem;
}
</style>
