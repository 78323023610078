<template>
    <a-modal
        v-model="addVisable"
        centered
        :footer="null"
        :width="1000"
        class="addDialog"
        @cancel="close"
        :destroyOnClose="true"
        :closable="false"
    >
        <div class="dialog_header">
            <h2>添加对比模型</h2>
            <div class="close"><svg-icon icon-class="icon_close" @click.native="close"></svg-icon></div>
        </div>
        <Search @search="onSearch" />
        <div 
            
        >
            <a-list :data-source="list" :loading="loading">
                <a-list-item slot="renderItem" slot-scope="item">
                    <div class="item">
                        <div class="txt">
                            <h2 class="title dot">{{item.Name}}</h2>
                            <div class="des dot2">{{item.Info}}</div>
                        </div>
                        <div class="handle">
                            <a-button 
                                v-for="modelItem in modelType" 
                                :key="modelItem.value"
                                @click="addItem(modelItem.value,item.Id)"
                            >
                                {{modelItem.name}}
                            </a-button>
                        </div>
                    </div>
                </a-list-item>
                <div v-if="busy" class="demo-loading-container">
                    <span>到底了！</span>
                </div>
            </a-list>
            <div class="page">
                <a-pagination 
                    :default-current="1" 
                    :total="total" 
                    :pageSize="params.pageSize"
                    @change="pageChange"
                />
            </div>
        </div>
    </a-modal>
</template>
<script>
import Search from '~/components/Learning/List/Search'
import { GetList } from '@/api/model'
export default {
    props: {
        dialogVisable: {
            type: Boolean,
            default: false,
        },
        modelType: {
            type: Array,
            default: [],
        },
    },
    components: {
        Search,
    },
    watch:{
        dialogVisable(v){
            this.addVisable = v
        }
    },
    data() {
        return {
            list: [],
            params: {
                pageIndex: 1,
                pageSize: 8,
                key: '',
                groupId: '',
                categoryId: '',
                fullText: '',
                title: '',
                tags: '',
                content: '',
                subject: '',
            },
            pagination: {
                onChange: page => {
                    this.params.pageIndex = page
                    this.getList()
                },
                pageSize: 8,
                total:this.total
            },
            total: 0,
            loading: false,
            busy: false,

            addVisable:false,
        }
    },
    methods: {
        close() {
            console.log(1);
            this.$emit('close')
        },
        // 搜索
        onSearch(obj) {
            console.log(obj);
            const { content , fullText , tags , title} = obj
            this.params.content = content
            this.params.fullText = fullText
            this.params.tags = tags
            this.params.title = title
            this.getList()
        },
        async getList() {
            this.loading = true
            const { data , total } = await GetList(this.params)
            // this.list = this.list.concat(data);
            this.list = data;
            this.total = total
            // this.busy = this.total == this.list.length
            this.loading = false
        },
        // 触底
        handleInfiniteOnLoad(){
            console.log(123);
        },
        // 翻页
        pageChange(page, pageSize){
            
            this.params.pageIndex = page
            this.getList()
            // this.getData()
        },
        // 选中
        addItem(type,id){
            this.$emit('add',{id,type})
            this.$emit('close')
        }
    },
    created(){
        this.getList()
    }
}
</script>
<style lang="less" scoped>
.demo-infinite-container{

    // padding-right: 20px;
    // overflow-y: hidden;
    &::-webkit-scrollbar{
        width: 6px;
        border-radius: 3px;
        background-color: #e5e5e5;
    }
    &::-webkit-scrollbar-thumb{
        width: 100%;
        border-radius: 3px;
        background-color: #f6a75c;
    }
}
.item{
    display: flex;
    padding: 20px 0 15px;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    .txt{
        flex: 1;
        h2{
            font-size: 18px;
            font-weight: bold;
            line-height: 1;
            margin-bottom: 10px;
        }
        .des{
            line-height: 1.5;
            font-size: 14px;
        }
    }
    .handle{
        flex-shrink: 0;
        margin-left: 20px;
        .ant-btn{
            background-color: #79f9d9;
            border-color: #79f9d9;
            padding: 0 15px;
            margin-left: 5px;
            &:focus,
            &:hover {
                background-color: #00ffc0;
                border-color: #00ffc0;
                color: #000;
                box-shadow: 0 0 10px 0 rgb(0, 255, 192 , 80%);
            }
        }
    }
}
:deep(.ant-list){
    overflow: auto;
    height: 500px;
    padding-right: 20px;
    &::-webkit-scrollbar{
        width: 6px;
        border-radius: 3px;
        background-color: #e5e5e5;
    }
    &::-webkit-scrollbar-thumb{
        width: 100%;
        border-radius: 3px;
        background-color: #f6a75c;
    }
}
.page{
    text-align: center;
    :deep(.ant-pagination-item),
    :deep(.ant-pagination-prev), 
    :deep(.ant-pagination-next){
        width: 40px;
        height: 40px !important;
        line-height: 40px !important;
    }
}
:deep(.ant-modal-body){
    padding-left: 50px;
    padding-right: 50px;
}
</style>