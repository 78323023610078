<template>
    <div class="outline" :class="'outline' + length">
        <div class="header">
            <div class="tag">{{data.typeInfo.name}}版</div>
            <a-tooltip>
                <template slot="title">删除</template>
                <div class="close" @click="close(item)">
                    <svg-icon icon-class="icon_close"></svg-icon>
                </div>
            </a-tooltip>

        </div>
        <a-spin :spinning="loading">
            <h2 class="title">{{data.info.Name}}</h2>
            <div class="model-tree">
                <ModelTree :modelType="data.typeInfo" :data="treeData" />
            </div>
        </a-spin>  
    </div>
</template>
<script>
import ModelTree from '../ModelTree.vue'
import { transformTreeData ,filterTreeData} from '~/assets/js/common'
import { GetAllListByModelId , GetExamListByModelId , GetKnowledgeListByModelId , GetNoteListByModelId } from '@/api/card'
export default {
    props:['length','data' ,"modelType"],
    components:{
        ModelTree
    },
    data(){
        return{
            getTree:{
                GetExamListByModelId , 
                GetKnowledgeListByModelId , 
                GetNoteListByModelId
            },
            treeData:[],
            loading:false
        }
    },
    methods:{
        async getTreeData(){
            this.loading = true
            const { data } = await GetAllListByModelId({modelId:this.data.id})
            console.log(this.modelType);
            filterTreeData(data,this.modelType.ids).then(res => {
                console.log(res);
                this.treeData = transformTreeData(res)
            })
            this.treeData = transformTreeData(data)
            this.$emit('change',{id:this.data.id,treeData:this.treeData})
            this.loading = false
        },
        close(){
            this.$emit('close',this.data.key)
        }
    },
    created(){
        this.getTreeData()
    }
}
</script>
<style scoped lang="less">
.outline{
    padding: 0 40px 50px 60px;
    max-width: 1440px + 110px;
    margin: 0 auto;
    position: relative;
    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tag{
            line-height: 30px;
            border-radius: 0 10px 0 10px;
            background-color: #00ffc0;
            padding: 0 10px; 
            color: #000;
            font-weight: bold;
            font-size: 14px;
            margin-bottom: 15px;
        }
        .close{
            width: 30px;
            height: 30px;
            text-align: right;
            cursor: pointer;
            color: #bbbbbb;
            transition: .3s;
            .svg-icon{
                font-size: 12px;
            }
            &:hover{
                color: #f6a75c;
            }
        }
    }
    .title{
        font-size: 20px;
        line-height: 1.4;
        color: rgb(0, 0, 0);
        font-weight: bold;
        margin-bottom: 20px;
    }
    .model-tree{
        flex: 1;
        position: relative;
    }
}
.outline3,
.outline4{
   padding: 0 20px 50px 20px;
   /deep/.model_main{
        padding-right: 15px;
   }
   /deep/.tree_title .svg-icon{
    margin-left: 15px;
   }
}
.outline1{
    width: 100%;
}
.outline2{
    width: 50%;
}
.outline3{
    width: 33.33%;
}
.outline4{
    width: 25%;
}
/deep/.ant-spin-nested-loading{
    height: 100%;
}
/deep/.ant-spin-container{
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .title{
        // flex-shrink: 0;
    }
    .model_main{
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}
/deep/.ant-spin-blur::after{
    background-color: #fff !important;
}
</style>